<template>
  <EditorFrame v-if="hb" :hb="hb" ref="frame">
    <PunctuationChange class="fr" :hb="hb" v-if="showPunc" @afterChange="puncChange" />
    <template>
      <div v-if="/Verse/.test(hb.contentType)">
        <VerseEditor :hb="hb" :showImgUploader="direct26" @change="contentChange" />
      </div>
      <div v-else-if="/Intensive/.test(hb.contentType)">
        <div v-if="direct26">精读文章暂时不支持直接到6审的编辑</div>
        <IntensiveEditor style="min-height: 18em" v-else :hb="hb" />
      </div>
      <div v-else>
        <ViewEditor ref="viewEditor" :showImgUploader="direct26" :hb="hb" @change="contentChange" />
      </div>
      <div v-if="'cnIntensivePoetry' === hb.category" class="flex">
        <div class="p-t-xs">背景图片：</div>
        <div class="backgrounds flex1">
          <div
            v-for="(backImg, i) in backgroundImgs"
            :key="i"
            style="text-align: center; margin: 0.5em 1em"
            :class="{
              'backImg-active':
                backImg.key === hb.attributeList[currentIndex].backgroudKey,
            }"
            @click="chooseBackImg(backImg)"
          >
            <img :src="backImg.url" width="60" height="96" />
            <div>{{ backImg.name }}</div>
          </div>
        </div>
      </div>
    </template>

    <template v-slot:buttons>
      <template v-if="$route.query.rewriteId">
        <Button class="center-block-btn" type="primary" @click="submitFn(0)">提交</Button>
        <Button class="center-block-btn" @click="save">保存</Button>
        <Button class="center-block-btn" type="error" @click="drop">放弃</Button>
      </template>
      <template v-else>
        <Button
          v-if="direct26"
          class="center-block-btn"
          type="primary"
          @click="submitFn(10)"
          :disabled="isDisable"
        >确认无误，提交</Button>
        <Button
          v-else-if="rolesRule"
          class="center-block-btn"
          type="info"
          @click="submitFn(8)"
          :disabled="isDisable"
        >提交到配图</Button>
        <Button
          v-else
          class="center-block-btn"
          type="primary"
          @click="submitFn(0)"
          :disabled="isDisable"
        >确认无误，提交</Button>

        <div v-if="!hb.parentId" class="next" @click="toDraft()" type="primary">保存至我的草稿，下次继续</div>
      </template>
    </template>
    <template v-slot:right-side>
      <Tabs :value="'name2'">
        <TabPane label="编辑状态" name="name2">
          <EditStatus ref="editStatus" :hb="hb" />
        </TabPane>
      </Tabs>
    </template>
  </EditorFrame>
  <!-- 编辑 -->
  <div v-else></div>
</template>

<script >
import lingYunApi from "../api/api";
import { createHb } from "../api/startTaskService";
import UploadImgService from "./components-for-editor/uploadBatch4Img";
import validateForm from "./components-for-editor/validateForm";
import PunctuationChange from "./PunctuationChange";
import {
  getRewriteContent,
  rewrite_drop,
  rewrite_save,
  rewrite_submit
} from "../api/startTaskService";
import {
  getHbTaskView,
  getBackgroundImgs,
  saveAsDraft
} from "../api/hbService";

export default {
  components: { PunctuationChange },
  data() {
    return {
      hb: null,
      rolesRule: false,
      isDisable: false, // 开关
      direct26: false,
      backgroundImgs: []
    };
  },
  computed: {
    showPunc() {
      return /^(en|cn)(View|Words|Sentence|Idiom|Teaching|Verse)$/.test(
        this.hb.contentType
      );
    }
  },
  mounted() {
    this.initTaskView();
    this.rolesRule = /import/.test(this.$store.getters.getRoles);
    this.direct26 = /submit_to_final/.test(this.$store.getters.getRoles);
    getBackgroundImgs().then(res => {
      this.backgroundImgs = res;
    });
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.initTaskView();
  },
  methods: {
    puncChange() {
      if (this.$refs.viewEditor) {
        this.$refs.viewEditor.$forceUpdate();
      }
      this.$Message.success("标点符号替换成功");
      this.contentChange();
    },
    contentChange() {
      this.$eventBus.$emit("hbChange");
      this.$refs.editStatus && this.$refs.editStatus.refresh();
    },
    chooseBackImg(backImg) {
      const info = this.hb.attributeList[this.currentIndex];
      info.backgroudKey = backImg.key;
      info.backgroudUrl = backImg.url;
    },
    async save() {
      let message =
        this.$refs.frame.validateBaseInfo() || (await validateForm(this.hb));
      if (message) {
        this.$Message.warning(message);
        return;
      }
      rewrite_save(this.hb)
        .then(res => {
          let { id, parentProcessId, parentId } = res;
          this.hb.id = id;
          this.hb.parentProcessId = parentProcessId;
          this.hb.parentId = parentId;
          this.$Message.success("保存成功");
        })
        .catch(err => {
          this.$Message.warning(err.message || err);
        });
    },
    drop() {
      rewrite_drop({
        parentId: this.hb.parentId || this.hb.id,
        parentProcessId: this.hb.parentProcessId || this.hb.processId,
        contentType: this.hb.contentType,
        taskName: this.hb.taskName
      })
        .then(() => {
          this.$router.back();
        })
        .catch(err => {
          this.$Message.warning(err.message || err);
        });
    },

    //获取任务详情
    async initTaskView() {
      this.currentIndex = 0;
      let {
        bizId,
        parentId,
        contentLevel,
        rewriteId,
        parentProcessId
      } = this.$route.query;
      let contentType = this.$route.params.contentType;
      let hb;
      if (parentId) {
        // parentId 来自回收站
        hb = (await getHbTaskView({ bizId: parentId, contentType })).content;
        hb.id = "";
        hb.taskId = "";
        hb.processId = "";
        hb.parentId = parentId;
        hb.parentProcessId = parentProcessId;
      } else if (rewriteId) {
        // ，rewriteId 来自 已发布的重写
        hb = await getRewriteContent({
          processId: parentProcessId,
          id: rewriteId,
          contentType,
          taskName: this.$store.getters.getRoleCode
        });
      } else if (bizId) {
        hb = (await getHbTaskView({ bizId, contentType })).content;
      } else {
        hb = {
          title: "",
          mdContentList: [""],
          backGroundList: [],
          contentTitle: [{ content: "", key: "", url: "", name: "" }],
          sort: null,
          attributeList: [
            {
              author: "",
              dynasty: "",
              contentSource: "",
              backgroudUrl: "",
              backgroudKey: "",
              musicKey: "",
              musicUrl: "",
              contentDivList: []
            }
          ],
          subheading: "",
          contentType,
          contentLevel,
          picMap: {}, //无需关心空指针
          id: "",
          status: 0,
          tagMap: { 0: [] },
          audioTag: [[]],
          imageTag: [[]],
          audioLines: [],
          ageType: "",
          category: "",
          secondCategory: "",
          taskName: 0,
          comment: ""
        };
      }

      (function() {
        // 这段代码也许你不知道这句是干啥的，但【千万不要删】，解释起来要从很远的历史说起！
        hb.audioMap = undefined;
        hb.taskName = 0;
        hb.customReadModule = hb.customReadModule || false;
        hb.readModuleList = hb.readModuleList || [];
        hb.intensiveReadConfigList = hb.intensiveReadConfigList || [];
      })();
      this.hb = hb;
    },
    // pass：0-正常提交，8-跨级提交到配图,10提交到6审
    async submitFn(pass = 0) {
      let message =
        this.$refs.frame.validateBaseInfo() || (await validateForm(this.hb));
      if (message) {
        this.$Message.warning(message);
        return;
      }
      this.hb.pass = pass;

      let msg;
      msg = this.$Message.loading({
        content: "提交中....",
        duration: 0
      });
      this.isDisable = true;
      if (10 === pass) {
        await UploadImgService(this.hb).catch(err => {
          this.$Message.warning(err);
          msg();
          this.isDisable = false;
          throw err;
        });
      }

      let { parentId, rewriteId } = this.$route.query;
      let submitFn;
      if (rewriteId) {
        // 重写
        submitFn = rewrite_submit;
      } else if (parentId) {
        // 回收站
        submitFn = lingYunApi.startHb;
      } else {
        // 草稿，创建，
        submitFn = createHb;
      }

      submitFn(this.hb)
        .then(res => {
          this.$Message.success("提交成功！");
          this.afterSaveSuccess();
          setTimeout(() => {
            if (this.$route.query.contentLevel) {
              // 新增的 分为两种情况，中途保存草稿的，和未保存草稿的
              if (this.$route.query.bizId) {
                this.$router.replace({
                  path: this.$route.path,
                  query: {
                    contentLevel: this.$route.query.contentLevel
                  }
                });
              } else {
                this.initTaskView();
              }
            } else if (this.$route.query.rewriteId) {
              // 已发布重写的
              this.$router.back();
            } else {
              // 草稿 与 回收站 ：
              window.close();
            }
          }, 500);
        })
        .catch(err => {
          this.$Message.warning(err);
        })
        .finally(() => {
          msg();
          this.isDisable = false;
        });
    },

    async toDraft(event) {
      let message =
        this.$refs.frame.validateBaseInfo() || (await validateForm(this.hb));
      if (message) {
        this.$Message.warning(message);
        return;
      }
      let msg = this.$Message.loading({
        content: "提交中....",
        duration: 0
      });
      saveAsDraft(this.hb)
        .then(res => {
          this.afterSaveSuccess();
          this.$Message.success("已保存到草稿!");
          this.hb.id = res.id;
          this.$router.replace({
            path: this.$route.path,
            query: {
              bizId: res.id,
              contentLevel: this.$route.query.contentLevel
            }
          });
        })
        .catch(err => {
          this.$Message.warning("保存草稿失败！");
        })
        .finally(() => {
          msg();
        });
    },
    afterSaveSuccess() {
      if (window.onDBModify) {
        window.onDBModify();
      }
    }
  }
};
</script>

<style scoped>
::v-deep .ivu-tabs {
  width: 600px;
}

.next {
  width: 14em;
  text-align: center;
  margin: 30px auto 0;
  color: #0099ff;
  cursor: pointer;
}
.backgrounds {
  display: flex;
  flex-wrap: wrap;
  max-height: 150px;
  overflow-y: auto;
  padding: 0 0.5em;
}
.backImg-active img {
  outline: 2px solid #09f;
}
</style>
