<template>
  <div>
    <Button
      v-if="hb.contentType.startsWith('en')"
      class="mybtn"
      @click="toEnPunc"
    >
      <sup>.</sup><sub>,</sub></Button
    >
    <Button v-else class="mybtn" @click="toCnPunc">
      <sup>。</sup><sub>，</sub></Button
    >
  </div>
</template>

<script>
/**
 * 中文符合---》 英文符号
 * 必须是一对多 或 1对1
 */
const trans2En = [
  ["，", ","],
  ["。", "."],
  ["！", "!"],
  ["：", ":"],
  ["？", "?"],
  ["；", ";"],
  ["“", '"'],            // 保证顺序:成对的符号 左侧的写在前面!
  ["”", '"'],
];
let trans2Cn = new Map();
for (let [v, k] of trans2En) {
  if (Array.isArray(k)) {
    for (let ki of k) {
      trans2Cn.set(ki, v);
    }
  } else if (!trans2Cn.has(k)) {
    trans2Cn.set(k, v);
  } else {
    let value = trans2Cn.get(k);
    if (Array.isArray(value)) {
      value.push(v);
    } else {
      trans2Cn.set(k, [value, v]);
    }
  }
}
trans2Cn = Array.from(trans2Cn.entries());

/**
 * @param {string} txt
 * @param {Array<[string,string|[]]>} transArr
 */
function transTxt(txt, transArr) {
  for (let [from, to] of transArr) {
    let splts = txt.split(from);
    txt = splts[0];
    for (let i = 1; i < splts.length; i++) {
      if (typeof to === "string") {
        txt += to + splts[i];
      } else {
        txt += to[0] + splts[i];
        to.push(to.shift());
      }
    }
  }
  return txt;
}

/**
 * @param { HTMLElement} ele
 */
function* allTextNodes(ele) {
  for (let nd of ele.childNodes) {
    if (nd.nodeType === 3) {
      yield nd;
    } else if (nd.nodeType === 1) {
      yield* allTextNodes(nd);
    }
  }
}
function transHtml(contentHtml, transArr) {
  const div = document.createElement("p");
  div.innerHTML = contentHtml; // test: '<p>hhh<span class="e">ee</span>rrr</p>www<p>222</p>';

  for (let n of allTextNodes(div)) {
    n.textContent = transTxt(n.textContent, transArr);
  }

  return div.innerHTML;
}
function isVerse(type) {
  return /^(cn|en)Verse$/.test(type);
}

export default {
  props: {
    hb: {},
  },
  methods: {
    toEnPunc() {
      const transObj = JSON.parse(JSON.stringify(trans2En));
      this._puncCore(transObj);
    },
    toCnPunc() {
      const transObj = JSON.parse(JSON.stringify(trans2Cn));
      this._puncCore(transObj);
    },
    _puncCore(transObj) {
      if (isVerse(this.hb.contentType)) {
        const contentObj = this.hb.backGroundList[this.currentIndex];
        let txts = contentObj.divElementList.filter((ele) => !ele.paragraphKey);
        txts.forEach((ele) => {
          ele.divContent = transTxt(ele.divContent, transObj);
        });
      } else {
        let html = this.hb.mdContentList[this.currentIndex];
        html = transHtml(html, transObj);
        this.hb.mdContentList[this.currentIndex] = html;
      }
      this.$emit('afterChange')
    },
  },
  mounted() {
    console.log(this.hb.contentType);
  },
};
</script>

<style scoped>
.mybtn {
  width: 3em;
  padding: 0;
  text-align: center;
  font-weight: bold;
}
</style>