import axios from './newAxiosProxy'

/**
 * 适用于提交 新创建的或来源于草稿的 app-文章
 */
export function createHb(data) {
    return axios({
        method: 'post',
        url: '/common/content/start/submit',
        data
    })
}
export function rewrite_submit(data) {
    return axios({
        method: 'post',
        url: '/contentExt/rewriteCommit',
        data
    })
}

export function rewrite_save(data) {
    return axios({
        method: 'put',
        url: '/contentExt/rewriteSave',
        data
    })
}

/**
 * 
 * @param {{parentId,parentProcessId,contentType,taskName}} data 
 */
export function rewrite_drop(data) {
    return axios({
        method: 'put',
        url: '/contentExt/abandonRewrite',
        data
    })
}

/**
 * 
 * @param {{ contentType, id }} params 
 */
export function getRewriteContent(params) {
    return axios({
        method: 'get',
        url: '/contentExt/rewriteView',
        params
    }).then(res=>{
       
    // 将里面空的图片，还原成标记路径
    res.mdContentList = res.mdContentList.map(htm => {
        const div = document.createElement('div');
        // 替换src处理，避免加载图片：
        div.innerHTML = htm.replace(/src/g, 'bsrc');
        div.querySelectorAll('img').forEach(ImgE => {
          if (!ImgE.getAttribute('bsrc')) {
            ImgE.setAttribute('bsrc', '/images/img-mark.jpg');
          }
        });
        return div.innerHTML.replace(/bsrc/g, 'src');
    });
      
      return res
    })
}