import axios from './newAxiosProxy';
import Vue from 'vue';
/**
 *
 * @param {Object} hb
 * @param {Number} index 包含多篇文章时指明是哪一篇，韵文这种只能有一篇文章的无效
 */
export function getContrastData(hb, index) {
  let singleMdContent;
  const mdContentList = [];
  if (/Verse/.test(hb.contentType)) {
    hb.backGroundList.forEach(picObj => {
      const str = picObj.divElementList.reduce((lastValue, item) => lastValue + '\n\n' + item.divContent, '');
      mdContentList.push(str.trim());
    });
  } else {
    singleMdContent = hb.mdContentList[index];
    // 兼容老数据 md的图片 ,去掉 ![]() 的格式
    singleMdContent = singleMdContent.replace(/!\[.*?\]\(.*?\)/g, '');
  }
  const contentLevel = hb.contentLevel;
  const contentType = hb.contentType;

  return axios(
    {
      method: 'post',
      url: '/common/content/wordDisplayCount',
      data: {
        singleMdContent,
        mdContentList,
        contentType,
        contentLevel
      }
    },
    true
  ).then(res => {
    let contrastDataList = res.chapterList || [];
    contrastDataList.push(res.chapter);
    contrastDataList = contrastDataList.filter(item => !!item);
    if (/Verse/.test(hb.contentType)) {
      for (let ind = 0; ind < contrastDataList.length; ind++) {
        contrastDataList[ind] = ind + 1 + ', ' + contrastDataList[ind];
      }
    }
    return contrastDataList;
  });
}

/**
 *
 * @param {{ processId, bizId, contentType, taskId }} params
 * @param {Function} callback
 */
export function getHbTaskView(params, callback = null) {
  return axios({ method: 'get', url: '/common/content/taskView', params }, callback || true).then(res => {
    callback && callback();
    // 解决数据丢失问题：
    if (res && res.content) {
      res.content.processId = params.processId;
      res.content.taskId = params.taskId;
      // 将里面空的图片，还原成标记路径
      res.content.mdContentList = res.content.mdContentList.map(htm => {
        const div = document.createElement('div');
        // 替换src处理，避免加载图片：
        div.innerHTML = htm.replace(/src/g, 'bsrc');
        div.querySelectorAll('img').forEach(ImgE => {
          if (!ImgE.getAttribute('bsrc')) {
            ImgE.setAttribute('bsrc', '/images/img-mark.jpg');
          }
        });
        return div.innerHTML.replace(/bsrc/g, 'src');
      });
      // 特殊需求：所有的备注都不显示！
      res.content.comment = ""
    }

    res.content = hbAdapt(res.content);
    return res;
  });
}

/**
 * 对老数据做数据适配，如新增的imageTag、audioTag等字段的适配工作
 * @param {*} hb
 */
export function hbAdapt(hb) {
  if (!hb) {
    Vue.prototype.$Message.warning('内容数据为空，请联系后台管理人员！');
    throw new Error();
  }
  // 适配老数据: audioTag imageTag
  const articleLen = (hb.mdContentList && hb.mdContentList.length) || 1; // 文章篇数：非韵文 或 韵文时为1
  if (!hb.audioTag || !hb.audioTag.length) {
    hb.audioTag = [];
    for (let i = 0; i < articleLen; i++) {
      hb.audioTag.push([]);
    }
  }
  if (!hb.imageTag || !hb.imageTag.length) {
    hb.imageTag = [];
    for (let i = 0; i < articleLen; i++) {
      hb.imageTag.push([]);
    }
  }
  if (!hb.attributeList) {
    hb.attributeList = [];
  }
  for (let i = 0; i < articleLen; i++) {
    hb.attributeList[i] = Object.assign(
      {
        author: '',
        dynasty: '',
        contentSource: '',
        backgroudUrl: '',
        backgroudKey: '',
        musicKey: '',
        musicUrl: '',
        contentDivList: []
      },
      hb.attributeList[i] || {}
    );
  }
  return hb;
}

/**
 * 获取单个已提交的内容（预览已提交中使用）
 * @param {{bizId,contentType,taskName}} params
 */
export function getSubmitedInfo(params) {
  return axios(
    {
      method: 'get',
      url: '/common/content/hisTaskView',
      params
    },
    true
  ).then(res => {
    res.content.taskName = params.taskName;
    res.content = hbAdapt(res.content);
    return res;
  });
}

export function longSentence_start(params) {
  return axios({
    method: 'post',
    url: '/longSentence/start',
    data: params
  });
}

export function  contentExt_updateIeng(params) {
  return axios({
    method: 'put',
    url: '/contentExt/updateIeng',
    data: params
  });
}

export const updatePublished = data => {
  return axios({
    url: '/common/content/updatePublished',
    method: 'put',
    data
  });
};

export function getBackgroundImgs() {
  return axios({
    url: '/contentExt/getBackgroundImg',
    method: 'get'
  });
}

export function saveAsDraft(data) {
  return axios({
    method: 'post',
    url: '/common/content/insert',
    data
  });
}

export function saveAsDraftf(data) {
  return axios({
    method: 'post',
    url: '/common/content/insert',
    data
  });
}
