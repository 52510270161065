

import { resolveHtml, updateHtmlByImg } from './view-editor/ResovleHtmlWithImgs'
import vue from 'vue'
export default async function (hb, validateFlag = true) {
    function getUploadQueenOfVerse(hb) {
        if (validateFlag) {
            // 校验图片是否足够
            let ErrInd = getDefectImgIndex(hb.backGroundList)
            if (ErrInd >= 0) {
                throw `第${ErrInd + 1}个标签页的图片不够！`
            }
        }
        // 获取需要上传的列表
        return hb.backGroundList.filter(line => line.file);
    }
    function getUploadQueenOfView(hb) {
        let uploadQueen = []
        for (let i = 0; i < hb.mdContentList.length; i++) {
            hb.picMap[i] = resolveHtml(hb.mdContentList[i], hb.picMap[i] || [])
            // 除了中文词语，其他的都不校验
            if (validateFlag && hb.contentType === 'cnWords') {
                if (getDefectImgIndex(hb.picMap[i]) >= 0) {
                    throw `第${i + 1}篇文章的图片不够！`
                }
            }
            uploadQueen.push(...hb.picMap[i].filter(item => item.file))
            if (hb.contentTitle[i].file) {
                hb.contentTitle[i].name = hb.contentTitle[i].file.name;
                uploadQueen.push(hb.contentTitle[i])
            }
        }
        return uploadQueen
    }
    function getUploadQueenOfIntensive(hb) {
        let uploadQueen = []
        for (let i = 0; i < hb.attributeList.length; i++) {
            uploadQueen.push(...hb.attributeList[i].contentDivList.filter(item => item.file))
            if (hb.contentTitle[i].file) {
                hb.contentTitle[i].name = hb.contentTitle[i].file.name;
                uploadQueen.push(hb.contentTitle[i])
            }
        }
        return uploadQueen
    }


    let uploadLines
    if (/Verse/.test(hb.contentType)) {
        uploadLines = getUploadQueenOfVerse(hb)
    } else if (/Intensive/.test(hb.contentType)) {
        uploadLines = getUploadQueenOfIntensive(hb)
    } else {
        uploadLines = getUploadQueenOfView(hb)
    }
    if (!uploadLines.length) {
        return;
    }
    await vue.prototype.$uploadService.uploadMutiple(uploadLines.map(ele => ele.file), true, ({ key, url }, ind) => {
        uploadLines[ind].file = undefined
        uploadLines[ind].key = key
        uploadLines[ind].url = url
    })
    if (!/Verse/.test(hb.contentType) && !/Intensive/.test(hb.contentType)) {
        // 非韵文且非精读 最后还要反向更新 html 字符串       
        for (let i = 0; i < hb.mdContentList.length; i++) {
            hb.mdContentList[i] = await updateHtmlByImg(hb.mdContentList[i], hb.picMap[i])
        }
    }
}

function getDefectImgIndex(list) {
    for (let i = 0; i < list.length; i++) {
        if (!list[i].url) {
            return i;
        }
    }
    return -1;
}