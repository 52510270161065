import newAxios from './newAxiosProxy'

const lingYunApi = {
  startHb(data) {
    return newAxios({
      method: 'post',
      url: `/contentExt/recycleContent`,
      data
    })
  },
  teaching_rewrite_submit(data) {
    return newAxios({
      method: 'post',
      url: '/teachingManage/rewriteCommit',
      data: data
    })
  },
  teaching_rewrite_save(data) {
    return newAxios({
      method: 'post',
      url: '/teachingManage/rewriteSave',
      data: data
    })
  },

  postSubmit: data => {

    let url = "/common/content/check/submit"
    if (/Teaching/.test(data.contentType)) {
      url = '/teachingManage/check/submit'
    }
    return newAxios({
      method: 'post',
      url,
      data
    })
  }, //修改内容、提交审核意见并提交工作流

  // allApi 存在bug，请勿在此添加代码！！！
  // allApi 存在bug，请勿在此添加代码！！！
  // allApi 存在bug，请勿在此添加代码！！！
}
export default lingYunApi

