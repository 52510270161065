/**
 * 
 * @param {*} obj 
 * @param {*} param1 
 */
export default async function (obj) {
    // 校验作者 朝代 背景图片（某些必须有： 精读的古诗词，中文韵文的古诗词）：
    if ('cnIntensivePoetry' === obj.category || 'cnVerseProse' === obj.category) {
        for (let i = 0; i < obj.attributeList.length; i++) {
            let { author, backgroudKey, dynasty } = obj.attributeList[i]
            if (!author.trim()) {
                return `第${i + 1}篇作者不能为空`;
            }
            if (!dynasty.trim()) {
                return `第${i + 1}篇朝代不能为空`;
            }
            if ('cnIntensivePoetry' === obj.category && !backgroudKey) {
                return `第${i + 1}篇背景图片不能为空`;
            }
        }
    }

    // 校验内容是否存在空的，非韵文的图片描述是否合法：
    if (/Verse/.test(obj.contentType)) {
        // 清理掉空格类字符 组成的行：
        obj.backGroundList.forEach(item => {
            if (item.divElementList[0]) {
                let cont = item.divElementList[0].divContent || "";
                item.divElementList[0].divContent = cont
                    .split("\n")
                    .map(item => (item.trim() ? item : ""))
                    .join("\n");
            }
        });
        for (let i = 0; i < obj.backGroundList.length; i++) {
            let buf = obj.backGroundList[i];
            if (
                !buf.divElementList[0] || !buf.divElementList[0].divContent
            ) {
                return `第${i + 1}个标签页的内容不能为空`;
            }
        }
        if ((obj.numCns[0] || 0) > 2000) {
            return '韵文总汉字数大于2000！';
        }
        if ((obj.numEns[0] || 0) > 2000) {
            return '韵文总单词数大于2000！';
        }
    } else if (/Intensive/.test(obj.contentType)) {
        // 精读文章
        for (let i = 0; i < obj.attributeList.length; i++) {
            let article = obj.attributeList[i]
            if (!article.contentDivList.length) {
                return `第${i + 1}篇文章没有内容`
            }
            for (let cell of article.contentDivList) {
                if (cell.divStyle === 'txt' && !cell.content.trim()) {
                    return `第${i + 1}篇文章存在内容为空的 ${cell.titleName}`
                }
            }
        }
    } else {
        // 需求变更：如果是绘本下，判断是否为章节书，只有章节才能有多篇        
        if (/^(cn|en)View$/.test(obj.contentType) && !/^(cn|en)ViewChapter$/.test(obj.category) && obj.mdContentList.length > 1) {
            return '绘本下只有章节书才能提交多篇文章'
        }

        for (let i = 0; i < obj.mdContentList.length; i++) {
            let div = document.createElement("div");
            div.innerHTML = obj.mdContentList[i];
            if (!div.getElementsByTagName("p").length) {
                return `第${i + 1}个标签页的内容不能为空`;
            }

            // if (!div.getElementsByTagName("img").length) {
            //     return `第${i + 1}个标签页的图片标记不能为空`;
            // }
            // 不能存在重复的图片描述，空的图片描述
            let capts = div.querySelectorAll('figure');
            let len = capts.length;
            capts = Array.from(capts).map(ele => ele.textContent.trim()).filter(ele => !!ele)
            if (len > capts.length) {
                return `第${i + 1}个标签页的图片描述不能为空`;
            }
            if (len > (new Set(capts)).size) {
                return `第${i + 1}个标签页的图片描述不能重复`;
            }
            if ((obj.numCns[i] || 0) > 2000) {
                return `第${i + 1}篇文章汉字数大于2000！`;
            }
            if ((obj.numEns[i] || 0) > 2000) {
                return `第${i + 1}篇文章单词数大于2000！`;
            }
        }
    }

    let titles
    // contentTitle标题是否为空,以及互相之间是否有重复的校验`
    if (!(/Verse/.test(obj.contentType))) {
        // 韵文没有文章标题     

        // 需求：英文句子 也可以没有文章标题,但为了批量配音必须有个标题
        if ("cnWords" === obj.contentType || 'enSentence' === obj.contentType) {
            if (!obj.contentTitle[0].content.trim()) {
                return "第1篇文章标题不能为空";
            }
        } else {
            for (let i = 0; i < obj.contentTitle.length; i++) {
                if (!obj.contentTitle[i].content.trim()) {
                    return `第${i + 1}篇文章标题不能为空`;
                }
            }
        }
        titles = obj.contentTitle.map(ele => ele.content.trim())
        if ('cnViewChapter' !== obj.category && 'enViewChapter' !== obj.category) {
            // 章节书 不做标题重复性校验
            for (let i = 0; i < titles.length; i++) {
                if (!titles[i]) {
                    continue
                }
                let j = titles.lastIndexOf(titles[i])
                if (j > i) {
                    return `第${i + 1}篇和第${j + 1}篇文章标题重复了`
                }

            }
        }

    } else {
        titles = [obj.title]
    }
    return "";
}